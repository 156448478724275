<template>
  <v-layout class='fill-height'>
    <v-container class='my-12' fluid>
      <v-row class='d-flex justify-center align-start'>
        <v-col lg='4' xl='3'>
          <v-row>
            <v-col cols='8'>
              <v-autocomplete
                filled
                dense
                solo
                hide-details
                clearable
                color='secondary'
                background-color='white'
                :no-data-text="$t('general.fields.noDataAvailable')"
                ref="tokenFilters"
                :label="$t('tokens.fields.selectToken')"
                item-color="primary"
                return-object
                :items="tokens"
                item-text="product"
                v-model="token"
                @change="fetchWallet($event)"
                @click:clear="clearData()"
                :disabled="!(web3.account) || loadingTokens || isSync"
                :loading="loadingTokens"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols='12'>
              <WalletCardComponent :token="token" :wallet="wallet" />
            </v-col>
            <v-col cols='12'>
              <v-card elevation='0'>
                <v-card-text v-if='web3.account && hasRole.final_customer' class='d-flex justify-center align-center align-content-center'>
                  <!--  VERIFY ROLE FOR SEND CREDIT || hasRole.final_customer -->
                  <!-- <WalletSendDialog v-if="hasRole.customer" :disabled='!token' :token='token || {}' />-->

                  <!--<v-tooltip top nudge-bottom='44' transition='fade-transition' content-class='tooltip__styles'>
                    <template v-slot:activator='{ on, attrs }'>
                      <v-btn :disabled='!web3.account' :text='!button.size' :outlined='button.size' v-on='on' v-bind='attrs' @click='copyAddress()' :color='button.color'>
                        <span class='hidden-sm-and-down font-weight-bold' :class='{ "text-lowercase" : button.size }'>{{ $t(button.text) }}</span>

                        <v-icon :small='button.size' :color='button.color' size='20px'>
                          {{ button.icon }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span> {{ $t('tokens.buttons.copyAddress') }} </span>
                  </v-tooltip>-->

                  <WalletSettleDialog v-if="hasRole.final_customer" :disabled='!token' @onSettleDialog='amountSettle = $event' :token='token || {}' />
                </v-card-text>
                <v-card-text v-else class='d-flex justify-center align-center align-content-center'>
                  <!--<v-btn disabled text color='secondary'>
                    <span class='hidden-sm-and-down font-weight-bold'>{{ $t('tokens.buttons.sendToken') }}</span>

                    <v-icon color='secondary' right size='20px'>
                      mdi-arrow-right-box
                    </v-icon>
                  </v-btn>

                  <v-btn disabled text color='secondary'>
                    <span class='hidden-sm-and-down font-weight-bold'>{{ $t(button.text) }}</span>

                    <v-icon color='secondary' right size='20px'>
                      {{ button.icon }}
                    </v-icon>
                  </v-btn>-->

                  <v-btn disabled text color='secondary'>
                    <span class='hidden-sm-and-down font-weight-bold'>{{ $t('tokens.buttons.settle') }}</span>

                    <v-icon color='secondary' right size='20px'>
                      mdi-file-certificate
                    </v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <WalletTotalsComponent :amount="amount" :token="token" :loading="loading" />
            </v-col>
          </v-row>
        </v-col>
        <v-col lg='7' xl='6'>
          <v-row class="py-2">
            <v-col cols='12'>
              <LoginMetamask view="WALLET" @onLogoutMetamask='clearData()'/>
            </v-col>
            <v-col cols='12'>
              <WalletHistoryComponent :token="token" />
            </v-col>
          </v-row>
        </v-col>
        <TimeSyncedComponent :token='token' section='WALLET' color="white" />
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
  import _ from 'lodash'
  import Web3  from 'web3'
  import { mapActions, mapGetters } from 'vuex'
  import sortList from '@/services/OrderListService'
  import WalletSettleDialog from '@/module/tokens/wallet/components/WalletSettleDialog'
  import WalletSendDialog from '@/module/tokens/wallet/components/WalletSendDialog'
  import WalletTotalsComponent from '@/module/tokens/wallet/components/WalletTotalsComponent'
  import WalletCardComponent from '@/module/tokens/wallet/components/WalletCardComponent'
  import WalletHistoryComponent from '@/module/tokens/wallet/components/WalletHistoryComponent'
  import LoginMetamask from '@/module/tokens/commons/LoginMetamask'
  import factoryJSON from '../../../../build/contracts/Factory.json'
  import ERC1155ModelJSON from '../../../../build/contracts/ERC1155Model.json'
  import TimeSyncedComponent from '@/module/tokens/commons/TimeSyncedComponent'

  export default {
    name: 'IndexView',
    components: { TimeSyncedComponent, LoginMetamask, WalletSendDialog, WalletSettleDialog, WalletTotalsComponent, WalletCardComponent, WalletHistoryComponent },
    data () {
      return {
        web3js: new Web3(Web3.givenProvider || "ws://localhost:8546"),
        loading: false,
        loadingTokens: false,
        filter: 'general',
        scrollInvoked: 0,
        button: {
          text: 'tokens.buttons.receive',
          icon: 'mdi-arrow-left-box',
          size: false,
          color: 'secondary'
        },
        receive: {
          text: 'tokens.buttons.receive',
          icon: 'mdi-arrow-left-box',
          size: false,
          color: 'secondary'
        },
        copy: {
          text: 'tokens.text.copied',
          icon: 'mdi-content-copy',
          size: true,
          color: 'secondary'
        },
        token: null,
        hasRole: {
          default_admin: false,
          customer: false,
          final_customer: false
        }
      }
    },

    computed: {
      ...mapGetters({
        web3: 'web3/GET_WEB3',
        wallet: 'web3/GET_WALLET',
        factory: 'web3/GET_FACTORY',
        tokens: 'web3/GET_TOKEN_LIST',
        amount: 'web3/GET_TOKEN_AMOUNT',
        roles: 'web3/GET_ROLES',
        isSync: 'web3/GET_IS_SYNC',
        validateAddress: 'general/ethereumAddress',
        maxRules: 'general/maxRules'
      })
    },

    async mounted() {
      await this.initialize()

      window.ethereum.on('accountsChanged', async () => {
        this.$store.commit('web3/DEFAULT_WALLET_DATA')
        this.$store.commit('web3/DEFAULT_TOKEN_DATA')
        await this.initialize()
      })

      this.$root.$on('initializeWalletData', async () => {
        await this.initialize()

        this.token = _.cloneDeep(this.tokens[0])
        await this.fetchWallet({ contractAddress: this.token.contractAddress })
      })

      this.$root.$on('loadTokenData', async (contractAddress) => {
        await this.fetchWallet({ contractAddress })
      })

      this.$root.$on('closeWalletData', async () => {
        this.token = null
      })
    },

    methods: {
      ...mapActions({
        ifUpdateAddress: 'web3/IF_UPDATE_ADDRESS',
        loadFactoryData: 'web3/LOAD_FACTORY_DATA',
        loadWalletData: 'web3/LOAD_WALLET_DATA',
        fetchTotalAmountByAction: 'web3/FETCH_TRACKING_TOTAL_BY_WALLET',
        startListeningWalletChanges: 'web3/START_LISTENING_WALLET_CHANGES',
        fetchStock: 'web3/FETCH_ACTIVE_STOCK',
        verifyRole: 'web3/HAS_ROLE'
      }),
      async initialize() {
        if (!window.ethereum) {
          console.log("Não foi detectado a extenção ou conexão com a Metamask.");
        }

        // window.userAddress = window.localStorage.getItem('eth_account');
        // this.$store.commit('web3/REGISTER_WEB3_INSTANCE', window.userAddress)

        this.ifUpdateAddress()
          .then(console.log);

        await this.web3js.eth.getAccounts()
          .then(accounts => {
            this.$store.commit('web3/REGISTER_WEB3_INSTANCE', accounts[0])
            window.userAddress = accounts[0]
          });

        this.startListeningWalletChanges()

        await this.loadFactoryData({factoryJSON, ERC1155ModelJSON}).finally(() => {
          console.log(this.factory)
        })

        this.loadingTokens = true
        await this.fetchStock().finally(() => {
          this.loadingTokens = false
        })
      },
      async fetchWallet(event) {
        this.data = []
        this.clearTable()
        this.$store.commit('web3/DEFAULT_TOKEN_DATA')

        if (event) {
          this.loading = true

          _.forEach(this.roles, async (role) => {

            await this.verifyRole({
              contractAddress: event.contractAddress,
              roleAddress: role.address,
              userAddress: window.userAddress,
              reference: event.contractAddress,
              wallet: { address: window.userAddress }
            }).then(res => {
              this.hasRole[role.role.toLowerCase()] = res
            })

          })

          const promises = []

          promises.push(await this.loadWalletData(event))
          promises.push(await this.fetchTotalAmountByAction([window.userAddress, this.token.id]))
          promises.push(await this.$root.$emit("fetchWalletHistory"))
          
          await Promise.all(promises).finally(() => {
            this.loading = false
          });
        }
      },
      orderData(list, order) {
        return sortList.orderListByUppercase(list, order)
      },
      clearData() {
        this.filter = 'general'
        this.general = []
        this.settle = []
        this.received = []
        this.send = []
        this.token = null
        this.hasRole = {
          default_admin: false,
          customer: false,
          final_customer: false
        }
      },
      async copyAddress() {
        this.button = _.cloneDeep(this.copy)

        await navigator.clipboard.writeText(this.web3.account).then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });

        setTimeout(() => {
          this.button = _.cloneDeep(this.receive)
        }, 1000)
      },
      
      clearTable() {
        this.general = []
        this.send = []
        this.received = []
        this.settle = []
      }
    },
    destroyed() {
      this.$root.$off('initializeWalletData')
      this.$root.$off('loadTokenData')
      this.$root.$off('closeWalletData')
      this.$store.commit('web3/DEFAULT_WALLET_DATA')
      this.$store.commit('web3/DEFAULT_TOKEN_DATA')
      this.token = null
    }
  }
</script>

<style scoped lang="scss">
  .layout {
    background:url("~@/assets/img/Logo-AgTrace.png") var(--v-primary-darken2) 173%;
    background-blend-mode: multiply;
    background-size: 60%;
    background-repeat: no-repeat;
  }

  .v-autocomplete {
    box-shadow: none !important;
  }

  .v-card {
    border-radius: .5em !important;
  }

  .tooltip__styles {
    background-color: rgba(255, 255, 255, 1);
    font-weight: bold;
    color: var(--v-primary-darken2);
  }
</style>